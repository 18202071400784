import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import { ContentAccordionWrap, AccordionLabel } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import AccordionComp from "./accordion";

interface IContentAccordion {
  label: React.ReactFragment;
  accData: any;
  type?: string;
}

const ContentAccordion: FC<IContentAccordion> = ({ label, accData, type }) => {
  const ContentWrapRef = useRef(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          ContentWrapRef?.current || null,
          0.55,
          { opacity: 0, y: 35 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  }, []);

  return (
    <ContentAccordionWrap ref={ContentWrapRef} className={type}>
      <AccordionLabel className={type? `${type} fs-xxl` : "fs-xxl"}>{label}</AccordionLabel>
      <AccordionComp items={accData} />
    </ContentAccordionWrap>
  );
};
export default ContentAccordion;
