import React, { FC, useEffect, useRef } from "react";
import { CompositionWrap } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import Image from "../image";

interface IComposition {
  imgName: string;
  align: string;
  altText?: string;
}

const Composition: FC<IComposition> = ({ imgName, altText, align }) => {
  const ContentWrapRef = useRef(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      end: "center top",
      animation: gsap
        .timeline()
        .fromTo(
          ContentWrapRef?.current || null,
          1.25,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          '+=1'
        )
    });
  }, []);

  return (
    <CompositionWrap className={align} ref={ContentWrapRef}>
      <Image imageName={imgName} altText={altText || "Sea life"} />
    </CompositionWrap>
  );
};

export default Composition;
