import * as React from "react";
import { seoData } from "../data/seo";
import { introData } from "../data/intro";
import { navData } from "../data/nav";
import { textData } from "../data/text";
import { accordionData, accordionLabel } from "../data/accordion";

import Seo from "../components/seo";
import IntroVideo from "../components/intro-video";
import Image from "../components/image";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import Composition from "../components/composition";
import ContentText from "../components/content-text";
import ContentAccordion from "../components/content-accordion";
import useWindowSize from "../helpers/UseWindowSize";
import NewComposition from "../components/composition/test";
import {CompositionWrapper} from "../components/composition/test/styled.index";
import leftPalms3 from "../assets/images/compos/marina_compos_3_left.png";
import rightPalms5 from "../assets/images/compos/marina_compos_5_right.png";
import leftPalms from "../assets/images/compos/marina_compos_1_left.png";
import rightPalms from "../assets/images/compos/marina_compos_1_right.png";
import rightPalms4 from "../assets/images/compos/marina_compos_4_right.png";
import leftMFooterM from "../assets/images/compos/marina-compos_mob_0_left.png";
import rightMFooterM from "../assets/images/compos/marina-compos_mob_0_right.png";

const MarinaTowerResidencesPage = () => {
  const {width} = useWindowSize();
  if (typeof window === `undefined`) {
    return(<></>);
  }
  return (
    <main>
      <Seo page={seoData.marinaTowerRes} />
      <IntroVideo page={introData.marinaTowerRes} />

      <CompositionWrapper>
        {width >= 1024 && <NewComposition imgSrc={leftPalms3} top={"65vh"} left={width >= 1300? "-200px" : "-18%"} height={'33vh'}/>}
        {width >= 1024 && <NewComposition imgSrc={rightPalms5} top={width >= 1310? '38vh' : '45vh'} right={width >= 1310? '0' : "-5%"} height={width >= 1310? '80vh' : "70vh"}/>}
        <ContentAccordion
          label={accordionLabel.marinaTowerResidences.label}
          accData={accordionData.marinaResidences}
          type={'resMob'}
        />
        <Image
          imageName={"marina-residences_1.jpg"}
          altText={"The Marina Tower"}
          padding={width >= 1885 ? "9.3% 9.3% 0 9.3%" : "9.3% 85px 0 85px"}
          paddingMob={"0"}
          move={false}
        />
      </CompositionWrapper>
      <CompositionWrapper>
        <section>
          <ContentText
            bgClassName={"bg high lpa"}
            heading={textData.marinaRes1.heading}
            text={textData.marinaRes1.text}
          />
          {width < 600 && <Composition imgName={"marina-residences-compos_mob_2.png"} align={"offTop4"} />}
          {width < 600 && <Composition imgName={"marina-residences-compos_mob_2_1.png"} align={"offBottom6"} />}
          {width >= 1024 && <NewComposition imgSrc={leftPalms} top={"-10vh"} left={width >= 1300? "0" : "-5%"} height={'118vh'}/>}
          {width >= 1024 && <NewComposition imgSrc={rightPalms} bottom={width >= 1300? "2vh" : "2vh"} right={width >= 1300? "0" : "-8%"} height={'75vh'}/>}
        </section>

        <Image
          imageName={"marina-residences_2.jpg"}
          altText={"The Marina Tower"}
          padding={"0"}
          move={true}
        />
      </CompositionWrapper>
      <CompositionWrapper>
        <Image
          imageName={"marina-residences_3.jpg"}
          altText={"The Marina Tower"}
          padding={width >= 1885 ? "9.3%" : "9.3% 85px"}
          paddingMob={"5.2%"}
          move={false}
        />
        <section>
          <ContentNextPage
            nextLink={navData.marinaTowerFloorPlans.link}
            nextTitle={navData.marinaTowerFloorPlans.next}
            vertAlign={'center'}
            textAlign={'center'}
            mobHeight={"430px"}
          />
          {width < 600 && <NewComposition imgSrc={leftMFooterM} bottom={"-20px"} left={width < 389 ? "-10px" : "-5px"} height={'88vw'}/>}
          {width < 600 && <NewComposition imgSrc={rightMFooterM} bottom={"30px"} right={"0"} height={width > 400 ? '90vw' : '88vw'}/>}
        </section>
        <Footer />
        {width >= 1024 && <NewComposition imgSrc={leftPalms} bottom={width >= 1300? "20vh" : '29vh'} left={width >= 1200? "0" : "-3%"} height={width >= 1300? '105vh' : '100vh'}/>}
        {width >= 1024 && <NewComposition imgSrc={rightPalms4} bottom={width >= 1600? "285px" : "310px"} right={width >= 1200? "0" : "-3.5%"} height={width >= 1300? '72vh' : "68vh"}/>}
      </CompositionWrapper>
    </main>
  );
};

export default MarinaTowerResidencesPage;
