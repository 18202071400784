import styled from "styled-components";

export const CompositionWrap = styled.div`
  width: 100%;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  &.bottom {
    bottom: 0;
  }
  &.top {
    top: 0;
  }
  &.mid {
    top: 23vh;
  }
  &.offTop {
    top: 5vh;
  }
  &.offTop2 {
    top: 2vh;
  }
  &.offTop3 {
    top: -3vh;
  }
  &.offTop4 {
    top: -2vh;
  }
  &.offBottom {
    bottom: 25vh;
  }
  &.offBottom2 {
    bottom: 15vh;
  }
  &.offBottom3 {
    bottom: 7.3vw;
  }
  &.offBottom4 {
    bottom: 2vh;
  }
  &.offBottom5 {
    bottom: 1vh;
  }
  &.offBottom6 {
    bottom: -2vh;
  }
`;
