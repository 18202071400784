import styled from "styled-components";
import {mediaMax} from "../../helpers/MediaQueries";
interface IStyle {
  maxWidth?: string;
}
export const ContentTextWrap = styled.div<IStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  position: relative;
  text-align: left;
  margin: 16.6vh 0;
  ${mediaMax.phoneXL`
    text-align: left;
  `}
  &.bg-full {
    height: 100vh;
    margin: 0;
  }
  &.bg-t1 {
    background-color: #f4f3ee;
    height: 110vh;
    margin: 0;
    ${mediaMax.phoneXL`
      height: 100vh;
    `};
    @media (max-height: 710px) {
      height: 100vh;
    };
  }
  &.bg-t2 {
    background-color: #f4f3ee;
    height: 95vh;
    margin: 0;
    ${mediaMax.phoneXL`
      height: 100vh;
    `}
  }
  &.bg {
    background-color: #f4f3ee;
    ${mediaMax.tabletLandscape`
      margin: 7.3% 0;
    `}
    ${mediaMax.phoneXL`
      min-height: 100vh;
    `}
    &.high {
      ${mediaMax.phoneXL`
        min-height: 100vh;
      `}
    }    
  }
  h1,
  p {
    max-width: ${(props) => props.maxWidth ? props.maxWidth : '425px'};
    width: 100%;
    padding: 20px;
    ${mediaMax.phoneXL`
      padding: 10px 10%;
    `}
  }
  h1 {
    padding-bottom: 0;
    color: #99948C;
  }
  &.bg-s {
    background-color: #f4f3ee;
  }
  &.lp {
    ${mediaMax.phoneXL`
      margin: 7.3% 0 5.2%;
    `}
  }
  &.lpa {
    ${mediaMax.phoneXL`
      margin: 5.2% 0;
    `}
  }
`;
