import styled from "styled-components";
import {mediaMax, mediaMin} from "../../helpers/MediaQueries";

export const ContentAccordionWrap = styled.div`
  padding: 20vh 9.3% 20vh 11%;
  display: flex;
  min-height: 100vh;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  ${mediaMax.desktopSmall`
      padding: 20vh 9% 20vh 9%;
  `}
  ${mediaMax.tabletLandscape`
  position: relative;
  z-index: 1;
  `}
  ${mediaMax.tablet`
    flex-direction: column;  
    align-items: flex-start;
    padding: 14vh 10% 8vh 10%;
    min-height: 105vh;
    justify-content: flex-start;
    &.resMob {
      min-height: 50vh;
      height: auto;
    }
  `}
  .accordionWrap {
    width: 44%;
    ${mediaMax.tabletLandscape`
      width: 100%;
    `}
  }

  .accordionItem {
    max-height: 550px;
  }

  .accordionButton {
    color: #c9c3b8;
    font-weight: 300;
    cursor: pointer;
    padding: 20px;
    width: 100%;
    text-align: left;
    position: relative;
    text-transform: uppercase;
    ${mediaMax.phoneXL`
      padding: 20px 20px 20px 25px;
    `};

    &:hover {
      ${mediaMin.tabletLandscape`
        color: #7a756d;
      `}
    }

    &:before,
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 12px;
      background-color: #d5d2c5;
      transition: 0.4s ease-out;
    }
    &:before {
      left: -30px;
      height: 1px;
      width: 28px;
      ${mediaMax.phoneXL`
        width: 14px;
        left: 0;
      `};
    }
    &:after {
      left: -16px;
      height: 28px;
      width: 1px;
      ${mediaMax.phoneXL`
        height: 14px;
        left: 7px;
      `};
    }
    &.accordionButton[aria-expanded="true"]::after,
    &.accordionButton[aria-selected="true"]::after {
      opacity: 0;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0;
    }
    100% {
      opacity: 1;
      height: 170px;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      height: 170px;
    }
    100% {
      opacity: 0;
      height: 0;
    }
  }
  .accordionPanel {
    max-width: 425px;
    opacity: 1;
    overflow: hidden;
    animation: 0.4s fadeIn ease-in;
    
    &[hidden] {
      max-width: 425px;
      opacity: 0;
      height: 0;
      overflow: hidden;
      display: block;
      animation: 0.4s fadeOut ease-out;
    }
    ${mediaMax.tabletLandscape`
      padding: 0;
    `}
    ul {
      line-height: 18px;
      padding: 10px 38px 40px;
      letter-spacing: 0.4px;
      ${mediaMax.tabletLandscape`
        padding: 10px 0 40px 38px;
      `}
      li {
        margin: 0;
        padding-left: 10px;
      }
    }
  }
`;

export const AccordionLabel = styled.div`
  color: #d5d2c5;
  text-transform: uppercase;
  padding-right: 40px;
  ${mediaMax.tabletLandscape`
    padding-bottom: 17vh;
    &.resMob {
      padding-bottom: 2.5vh;
    }
  `}
`;
