import React, { FC, useEffect, useRef } from "react";

import { ContentTextWrap } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

interface IContentText {
  bgClassName?: string;
  heading?: string | React.ReactFragment;
  text?: string | React.ReactFragment;
  label?: string;
  maxWidth?: string;
  type?: string;
}

const ContentText: FC<IContentText> = ({
  bgClassName,
  heading,
  text,
  label,
  maxWidth,
  type
}) => {
  const contentWrapRef = useRef<HTMLDivElement>(null);
  const headRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: headRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=20% bottom-=35%",
      end: "center top",
      animation: gsap
        .timeline()
        .fromTo(
          headRef?.current || null,
          1.25,
          { opacity: 0, y: 40, ease: 'Power.easeInOut' },
          { opacity: 1, y: 0, ease: 'Power.easeInOut' },
          ".5"
        )
        .fromTo(
          textRef?.current || null,
          1.25,
          { opacity: 0, y: 40, ease: 'Power.easeInOut' },
          { opacity: 1, y: 0, ease: 'Power.easeInOut' },
          ".5"
        )
    });
  }, []);

  return (
    <ContentTextWrap className={bgClassName} ref={contentWrapRef} maxWidth={maxWidth}>
      {heading && (
        <h1 className={"heading fs-xl"} ref={headRef}>
          <span>{label}</span>
          {heading}
        </h1>
      )}
      {text && <p className={`reg-text ${type}`} ref={textRef}>{text}</p>}
    </ContentTextWrap>
  );
};
export default ContentText;
